.wave-left {
  height: 30px;
  overflow: hidden;
  position: absolute;
  top: 6%;
  left: -80%;
  transform: rotateY(180deg) scaleX(1.3);
}

.wave-right {
  height: 30px;
  overflow: hidden;
  position: absolute;
  top: 6%;
  right: -80%;
  transform: scaleX(1.3);
}

.switch {
  position: relative;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: none;
}

.switch input {
  pointer-events: none;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
}

.switch span {
  background: #000;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.5s, width 0.5s;
}

.switch .line-1 {
  width: 50%;
  transform: translate(-100%, -10px);
  transform-origin: 0 0;
}

.switch .line-3 {
  width: 50%;
  left: auto;
  right: 0;
  transform: translate(0, 10px);
  transform-origin: 100% 0;
}

input:checked + div span.line-1 {
  transform: translate(0, 0) rotate(-45deg);
}

input:checked + div span.line-3 {
  transform: translate(-100%, 0) rotate(-45deg);
}

input:checked + div span.line-2 {
  transform: translate(-50%, -50%) rotate(45deg);
}
