.container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.container {
  display: block;
  position: relative;
  font-size: 20px;
  user-select: none;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  pointer-events: none;
}

.line {
  width: calc(100% - 8px);
  height: 3px;
  left: 4px;
  background: rgb(58, 58, 58);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.7s transform cubic-bezier(0, 1, 0.33, 1.2);
}

.line-indicator {
  transform: translateY(-50%) rotate(90deg);
}

.container input:checked ~ .line-indicator {
  transform: translateY(-50%);
}
