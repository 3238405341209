.cont {
  display: flex;
  align-items: center;
  transform: scale(0.6);
}

.checkbox {
  height: 1.7rem;
  width: 1.7rem;
  margin: 5px;
  display: inline-block;
  appearance: none;
  position: relative;
  border: 2px solid var(--color-dark-blue);
  background-color: var(--color-white);
  border-radius: 15%;
  cursor: pointer;
  overflow: hidden;
  transition: border-radius 0.2s ease;
}

.checkbox:hover {
  border-radius: 50%;
}

.checkbox::after {
  content: "";
  display: block;
  height: 0.8rem;
  width: 0.3rem;
  border-bottom: 0.31rem solid #ff0000;
  border-right: 0.31rem solid #ff0000;
  opacity: 0;
  transform: rotate(45deg) translate(-50%, -50%);
  position: absolute;
  top: 45%;
  left: 21%;
  transition: 0.25s ease;
}

.checkbox::before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  background-color: #ff000011;
  border-radius: 50%;
  opacity: 0.5;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  transition: 0.3s ease;
}

.checkbox:checked::before {
  height: 130%;
  width: 130%;
  opacity: 100%;
}

.checkbox:checked::after {
  opacity: 100%;
}

.checkbox-span {
  font-size: 2rem;
}
