.toggleSwitch {
  --secondary-container: var(--color-dark-blue);
  --primary: #84da89;
  font-size: 13px;
  position: relative;
  top: 7px;
  left: 10px;
  display: inline-block;
  width: 3.7em;
  height: 1.8em;
}

.toggleSwitch input {
  display: none;
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-dark-blue);
  transition: 0.2s;
  border-radius: 30px;
}

.slider:hover {
  opacity: 0.9;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.2em;
  bottom: 0.2em;
  background-color: var(--color-light-grey);
  transition: 0.4s;
}

input:checked + .slider::before {
  background-color: var(--primary);
}

input:checked + .slider {
  background-color: var(--secondary-container);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--secondary-container);
}

input:checked + .slider:before {
  transform: translateX(1.9em);
}
